<template>
  <div class="body">
    <div class="header">
      <div class="title">
        <p>首页</p>
      </div>
      <div class="search-box">
        <svg t="1703487356717" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4293" width="200" height="200"><path d="M966.4 924.8l-230.4-227.2c60.8-67.2 96-156.8 96-256 0-217.6-176-390.4-390.4-390.4-217.6 0-390.4 176-390.4 390.4 0 217.6 176 390.4 390.4 390.4 99.2 0 188.8-35.2 256-96l230.4 227.2c9.6 9.6 28.8 9.6 38.4 0C979.2 950.4 979.2 934.4 966.4 924.8zM102.4 441.6c0-185.6 150.4-339.2 339.2-339.2s339.2 150.4 339.2 339.2c0 89.6-35.2 172.8-92.8 233.6-3.2 0-3.2 3.2-6.4 3.2-3.2 3.2-3.2 3.2-3.2 6.4-60.8 57.6-144 92.8-233.6 92.8C256 780.8 102.4 627.2 102.4 441.6z" p-id="4294" fill="#8a8a8a"></path></svg>
        <input placeholder="搜索" v-model.trim="search" type="text" @input="changeKeyword">
      </div>
      <div class="category-box">
        <a :class="['category',index==0?'active':'']" @click="changeStatus(0)">全部</a>
        <a :class="['category',index==1?'active':'']" @click="changeStatus(1)">已完成</a>
        <a :class="['category',index==2?'active':'']" @click="changeStatus(2)">未完成</a>
      </div>
    </div>
    <div class="main" >
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <div v-for="(item,index) in evidenceList" :key="index" @click="gotoEvidence(item.id,item.status)" class="evidence">
            <van-swipe-cell style="width: 100%;">
               <div style="display: flex;" >
                 <img :src="`http://record.ggws.com.cn/${JSON.parse(item.evidencePic)[0]}`" alt="">
                 <div class="evidence-text">
                   <p>{{ item.docNo }}</p>
                   <div class="evidence-detail">
                     <!-- <a>{{ item.location }}</a> -->
                     <div>{{ item.createTime }}</div>
                   </div>
                 </div>
                 <div class="evidence-status noComplete" v-if="item.status==1">已完成</div>
                 <div class="evidence-status completing" v-else-if="item.status==4">待确认</div>
                 <div class="evidence-status complete" v-else>未完成</div>
                 <svg t="1703488466039" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5360" width="200" height="200"><path d="M289.301454 938.361551c8.958022 8.93551 24.607444 7.868201 34.877345-2.312672l405.886217-403.662573c5.846148-5.780657 8.581446-13.271258 8.314363-20.306488 0.331551-7.080256-2.423189-14.637372-8.270361-20.463054L324.178799 87.966471c-10.269901-10.225899-25.875321-11.248182-34.877345-2.322905-8.960069 8.946766-7.936763 24.451902 2.334161 34.666544l393.880789 391.68068L291.635615 903.68375C281.364691 913.908626 280.341385 929.423995 289.301454 938.361551z" fill="#5D5E5E" p-id="5361"></path></svg>                
               </div>
               <template #right>
                <van-button square type="danger" text="删除" @click="deleteItem(item)"/>
               </template>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>
      <div style="height: 100px;width: 100%;"></div>
    </div>
    <div @click="gotoEvidence('none')" class="footer">
      新建笔录
    </div>
  </div>
</template>

<script>
import {getEvidence,deleteEvidence} from "@/api";
export default {
  data(){
    return{
      index:0,
      evidenceList:[],
      busy:false,
      pageSize: 10,
      pageNum:1,
      search:'',
      status:'',
      refreshing:false,
      loading:false,
      finished:false,
      total:null
    }
  },
  created(){
    this.init()
  },
  methods:{
    gotoEvidence(id,status){
      if(id!=='none'&&status!=1){
        this.$router.push({
          name:'Evidence',
          query:{id:id}
        })
      }else if (id!=='none'&&status===1) {
        this.$router.push({
          name:'Attest',
          params:{id:id},
          query:{status:status}

        })
      }else{
        this.$router.push('/evidence')
      }
    },
    onRefresh(){
      this.finished=false
      this.loading=true
      this.pageNum=1
      this.evidenceList=[],
      this.init()
    },
    onLoad(){
      if (this.evidenceList.length>=this.total) {
        this.finished=true
        return
      }
      this.loading=true
      this.pageNum++
      this.init()
    },
    init(){
      getEvidence([this.pageNum,this.pageSize,this.search,this.status]).then(res => {
        this.refreshing=false
        this.loading=false
        const { rows,total } = res
        this.total=total
        this.evidenceList =[...this.evidenceList,...rows]
      })
    },
    changeStatus(val){
      this.evidenceList=[]
      this.finished=false
      this.index=val
      this.pageNum=1
     switch (val) {
      case 0:
      this.status=''
      break;
      case 1:
      this.status=[1]
      break;
      case 2:
      this.status=[2,3,4]
      break;
      default:
      break;
     }
     this.init()
    },
    loadMore: function() {
      this.busy = true
      this.pageNum++
      getEvidence([this.pageNum,this.pageSize,this.search,this.status]).then(res => {
        const { rows } = res
        console.log(rows)
        if(rows.length != 0){
          this.evidenceList=[...this.evidenceList,...rows]
          this.busy = false
        }
      })
    },
    changeKeyword(){
      this.pageNum=1
      this.evidenceList=[]
      this.init()
    },
   async deleteItem(val){
    if (val.status!==2) {
      this.$Dialog.confirm({
          title: '提示',
          message: '删除后另一取证人报告记录将被同步删除，且无法找回该记录，你确认删除吗？',
      }).then(async() => {
            const res= await deleteEvidence(val.id)
            if (res.code==200) {
            this.$Toast.success('删除成功')
            this.evidenceList=[]
            this.pageNum=1
            this.init()
           }
          })
          .catch(() => {
            // on cancel
          });      
    }else if (this.$store.state.user.userInfo.id!=val.evidenceCollectorId&&this.$store.state.user.userInfo.id!=val.collaboratorId) {
      return this.$Toast.fail('暂无删除权限')
    }else{
      const res= await deleteEvidence(val.id)
      if (res.code==200) {
        this.$Toast.success('删除成功')
        this.evidenceList=[]
        this.pageNum=1
        this.init()
      }
    }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.body{
  width: 100%;
  position: relative;
  height: 100vh;
}
.header{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom,rgb(68,158,255),#fff);
}
.title{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title p{
  font-weight: 700;
  font-size: 18px;
}
.search-box{
  width: 90%;
  height: 32px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.search-box .icon{
  height: 20px;
  width: 20px;
  margin-left: 10px;
}
.search-box input{
  outline: none;
  border: none;
  margin-left: 10px;
  width: 80%;
  height: 30px;
}
.category-box{
  width: 90%;
  margin-top: 40px;
}
.category-box a:nth-child(2){
  margin-left: 15px;
}
.category-box a:nth-child(3){
  margin-left: 15px;
}
.category.active{
  font-weight: 600;
  border-bottom: 4px solid rgb(49,142,240);
}
.main{
  margin-top: 10px;
  height: calc(100% - 160px);
  width: 100%;
  overflow: auto;
  /* padding-bottom: 30px; */
}
.evidence{
  border-bottom: 2px solid rgb(229,229,229);
  height: 70px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 5px auto;
}
.evidence img{
  height: 60px;
  width: 60px;
}
.evidence-text{
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  width: 73%;
}
.evidence-text p{
  font-size: 19px;
  font-weight: 500;
}
.evidence-detail{
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #999999;
  font-size: 16px;

}
.evidence-status{
  position: absolute;  z-index: 1000;
  height: 16px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 50%;
}
.complete{
  color: rgb(118,211,166);
  border: 2px solid rgb(118,211,166);
}
.noComplete{
  color: rgb(244,178,136);
  border: 2px solid rgb(244,178,136);
}
.completing{
  color: rgb(100,175,222);
  border: 2px solid rgb(100,175,222);
}
.evidence .icon{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0%;
  top: 20%;
}
.footer{
  width: 80%;
  height: 40px;
  color: white;
  position: fixed;
  left: 50%;
  margin-left: -40%;
  bottom: 5%;
  background-color: rgb(18,132,255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  z-index: 10001;
}
</style>